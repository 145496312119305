<template>
  <div id="canvas-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <router-link to="/canvas" class="btn btn-secondary mb-5 mr-3">Zurück zur Übersicht</router-link>
    <button @click="save" class="btn btn-primary mb-5">Canvas speichern</button>

    <!--<p>Todo:</p>
    <ul>
      <li>Build Calculations</li>
      <li>Build Charts</li>
      <li>Connect to <router-link to="/experiments">Experiments</router-link></li>
      <li>Connect to <router-link to="/journeys">Customer Journeys</router-link></li>
      <li>Connect to <router-link to="/channels">Traffic Channels</router-link></li>
    </ul>-->

    <div class="card mb-3" :class="step === 0 ? 'border-primary' : ''">
      <div @click="step = 0" class="card-header" :class="step === 0 ? 'border-primary' : ''">
        <h3 class="mb-0">Überblick: "{{ newCanvas.title }}"</h3>
      </div>
      <div class="card-body pt-2">
        <div class="row">
          <div class="col-sm-6 mt-2">
            <h5>Unternehmensseite</h5>
          </div>
          <div class="col-sm-6 mt-2">
            <h5>Kundenseite</h5>
          </div>
          <div class="col-sm-6 py-2">
            <div @click="step = 3" class="card h-100 clickable" :class="step === 3 ? 'border-primary' : ''">
              <div class="card-body px-3 pt-3 pb-0">
                <h6 class="mb-1"><i class="fad fa-fw fa-plus-circle text-success"></i> Welche Vorteile du lieferst</h6>
                <ul class="list-unstyled">
                  <li v-for="(gainCreator, index) in newCanvas.gainCreators" :key="index">
                    <input :value="gainCreator" class="form-control form-control-sm naked" placeholder="Gain Creator eingeben..." type="text"
                           @change="change($event, index, 'gainCreators')">
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-sm-6 py-2">
            <div @click="step = 5" class="card h-100 clickable" :class="step === 5 ? 'border-primary' : ''">
              <div class="card-body px-3 pt-3 pb-0">
                <h6 class="mb-1"><i class="fad fa-fw fa-wrench text-success"></i> Was sie suchen</h6>
                <ul class="list-unstyled">
                  <li v-for="(gain, index) in newCanvas.gains" :key="index">
                    <input :value="gain" class="form-control form-control-sm naked" placeholder="Gain eingeben..." type="text" @change="change($event, index,
                    'gains')">
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-sm-6 py-2">
            <div @click="step = 1" class="card h-100 clickable" :class="step === 1 ? 'border-primary' : ''">
              <div class="card-body px-3 pt-3 pb-0">
                <h6 class="mb-1"><i class="fad fa-fw fa-box-open text-primary"></i> Produkte & Services</h6>
                <ul class="list-unstyled">
                  <li v-for="(product, index) in newCanvas.products" :key="index">
                    <input :value="product" class="form-control form-control-sm naked" placeholder="Produkt eingeben..." type="text" @change="change($event,
                    index,
                    'products')">
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-sm-6 py-2">
            <div @click="step = 4" class="card h-100 clickable" :class="step === 4 ? 'border-primary' : ''">
              <div class="card-body px-3 pt-3 pb-0">
                <h6 class="mb-1"><i class="fad fa-construction"></i> Zu erledigende Jobs</h6>
                <ul class="list-unstyled">
                  <li v-for="(job, index) in canvasJobs" :key="index">
                    <input :value="job.title" class="form-control form-control-sm naked" placeholder="Job-Titel eingeben..." type="text"
                           @change="change($event, index, 'job.title')">
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-sm-6 py-2">
            <div @click="step = 2" class="card h-100 clickable" :class="step === 2 ? 'border-primary' : ''">
              <div class="card-body px-3 pt-3 pb-0">
                <h6 class="mb-1"><i class="fad fa-fw fa-band-aid text-warning"></i> Welche Probleme du behebst</h6>
                <ul class="list-unstyled">
                  <li v-for="(painReliever, index) in newCanvas.painRelievers" :key="index">
                    <input :value="painReliever" class="form-control form-control-sm naked" placeholder="Pain Reliever eingeben..." type="text"
                           @change="change($event, index, 'painRelievers')">
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-sm-6 py-2">
            <div @click="step = 6" class="card h-100 clickable" :class="step === 6 ? 'border-primary' : ''">
              <div class="card-body px-3 pt-3 pb-0">
                <h6 class="mb-1"><i class="fad fa-fw fa-bolt text-warning"></i> Was sie vermeiden wollen</h6>
                <ul class="list-unstyled">
                  <li v-for="(pain, index) in newCanvas.pains" :key="index">
                    <input :value="pain" class="form-control form-control-sm naked" placeholder="Pain eingeben..." type="text" @change="change($event, index,
                    'pains')">
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <h5 class="mt-3">Einzigartige Wertversprechen</h5>
        <div @click="step = 7" class="card h-100 clickable" :class="step === 7 ? 'border-primary' : ''">
          <div class="card-body">
            <ul v-if="newCanvas.valueProps.length" class="fa-ul mb-0">
              <li v-for="(valueProp, index) in newCanvas.valueProps" :key="index">
                <span class="fa-li text-primary"><i class="fad fa-gem"></i></span>{{ valueProp }}
              </li>
            </ul>
            <div v-else class="text-muted">
              <p class="mb-0">Keine UVPs vorhanden</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="step <= 7" class="card mb-5">
      <div class="card-header">
        <h2 class="mb-0">Value Proposition Canvas</h2>
      </div>
      <div class="card-body">

        <div v-if="step === 0">
          <h3>Willkommen! Schön, dass du da bist.</h3>
          <p>
            Hier geht es darum, das Problem das dein Unternehmen löst und was es dabei so besonders macht möglichst prägnant zu formulieren.
          </p>
          <hr>

          <h4>Benenne zuerst deinen Canvas</h4>
          <p>Nimm zum Beispiel den Namen des Produkts oder der Produktlinie, die du hier beschreiben möchtest.</p>
          <input type="text" v-model="newCanvas.title" class="form-control">
        </div>

        <div v-else-if="step <= 3">
          <div class="row">
            <div class="col-12">
              <div>
                <h3>Die Unternehmensseite</h3>
                <p>
                  Die Firmenseite beschreibt was dein Unternehmen seinen Kunden anbietet und welche Probleme damit gelöst
                  werden können und welchen Nutzen die Firma damit zusätzlich generiert.
                </p>

                <hr>

                <div v-if="step === 1">
                  <h4 class="mt-3">
                    <i class="fad fa-fw fa-box-open text-primary"></i>
                    Produkte & Services
                  </h4>
                  <p class="lead">
                    Wie würdest du deine Produkte und Dienstleistungen kurz beschreiben?
                  </p>
                  <ul class="list-group">
                    <li v-for="(product, index) in newCanvas.products" :key="index" class="list-group-item">
                      <input :value="product" class="form-control naked" placeholder="Gain Creator eingeben..." type="text"
                             @change="change($event, index, 'products')">
                      <i @click="remove('products', index)" class="fal fa-minus-circle text-danger float-right"></i>
                    </li>
                    <li class="list-group-item">
                      <input type="text" @change="add('products', $event)" class="form-control form-control-sm" placeholder="Neuen Punkt hinzufügen..."/>
                    </li>
                  </ul>
                </div>

                <div v-if="step === 2">
                  <h4 class="mt-3">
                    <i class="fad fa-fw fa-band-aid text-warning"></i>
                    Pain Relievers
                  </h4>
                  <p class="lead">
                    Welche Probleme lösen deine Produkte beim Kunden?
                  </p>
                  <ul class="list-group">
                    <li v-for="(painReliever, index) in newCanvas.painRelievers" :key="index" class="list-group-item">
                      <input :value="painReliever" class="form-control naked" placeholder="Pain Reliever eingeben..." type="text"
                             @change="change($event, index, 'painRelievers')">
                      <i @click="remove('painRelievers', index)" class="fal fa-minus-circle text-danger float-right"></i>
                    </li>
                    <li class="list-group-item">
                      <input type="text" @change="add('painRelievers', $event)" class="form-control form-control-sm" placeholder="Neuen Punkt hinzufügen..."/>
                    </li>
                  </ul>
                </div>

                <div v-if="step === 3">
                  <h4 class="mt-3">
                    <i class="fad fa-fw fa-plus-circle text-success"></i>
                    Gain Creators
                  </h4>
                  <p class="lead">
                    Welchen zusätzlichen Nutzen erhalten Kunden dadurch?
                  </p>
                  <ul class="list-group">
                    <li v-for="(gainCreator, index) in newCanvas.gainCreators" :key="index" class="list-group-item">
                      <input :value="gainCreator" class="form-control naked" placeholder="Gain Creator eingeben..." type="text"
                             @change="change($event, index, 'gainCreators')">
                      <i @click="remove('gainCreators', index)" class="fal fa-minus-circle text-danger float-right"></i>
                    </li>
                    <li class="list-group-item">
                      <input type="text" @change="add('gainCreators', $event)" class="form-control form-control-sm" placeholder="Neuen Punkt hinzufügen..."/>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="step <= 6">
          <h3>Zu deinen Kunden.</h3>
          <p>
            Deine Kunden kennen deine Produkte womöglich nicht einmal sehr gut. Wo kannst du ansetzen, um ihnen den
            bestmöglichen Wert und Nutzen zu bieten?
          </p>
          <hr>

          <div v-if="step === 4" class="row">
            <div class="col-12">
              <h4>Die Jobs deiner Kunden</h4>
              <p class="lead">
                Welches Problem versuchen deine Kunden zu lösen?
              </p>

              <div v-if="showNewJob">
                <h5>Neuen Job erstellen</h5>

                <div class="row">
                  <div class="col-md-6 order-md-2">
                    <h6>Erklärung</h6>
                    <p>
                      Ein User Job, oder <i>Job to be done</i>, ist eine Aufgabe, die dein Produkt oder deine Dienstleistung erfüllt.
                      Solch ein Job kann funktional sein, also einfach einen grundlegenden Zweck erfüllen, aber auch sozialer oder emotionaler Natur sein.
                      Soziale und emotionale Jobs sind nicht immer auf den ersten Blick erkennbar, daher hier ein Beispiel:
                    </p>
                    <h6>Produkt: Hermés Gürtel</h6>
                    <ul class="fa-ul">
                      <li><span class="fa-li"><i class="fad fa-wrench"></i></span><b>Funktionaler Job:</b> Hose befestigen</li>
                      <li><span class="fa-li"><i class="fad fa-users"></i></span><b>Sozialer Job:</b> Status präsentieren</li>
                      <li><span class="fa-li"><i class="fad fa-heart"></i></span><b>Emotionaler Job:</b> Belohnung für einen selbst</li>
                    </ul>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <h6>Benenne den Job:</h6>
                      <input type="text" v-model="newJob.title" class="form-control">
                    </div>

                    <div class="form-group">
                      <h6>Welcher Art soll der neue Job deines Produkts sein?</h6>
                      <select v-model="newJob.type" required class="form-control">
                        <option value="" disabled>Job-Typ auswählen</option>
                        <option value="functional">Funktional</option>
                        <option value="social">Sozial</option>
                        <option value="emotional">Emotional</option>
                      </select>
                    </div>

                    <div class="form-group">
                      <h6>Wie würdest du den Job beschreiben:</h6>
                      <textarea v-model="newJob.description" class="form-control"></textarea>
                    </div>

                    <button @click="showNewJob = false" class="btn btn-secondary mr-3">Abbrechen</button>
                    <button @click="addJob" class="btn btn-primary">Job hinzufügen</button>
                  </div>
                </div>

              </div>

              <div v-else class="row">
                <div class="col-md-6">
                  <h5>Deine bisher erstellten Jobs:</h5>
                  <ul class="fa-ul">
                    <li v-for="(job, index) in jobs" :key="index">
                  <span class="fa-li"><i :class="job.type === 'functional' ? 'fa-wrench' : job.type === 'social' ? 'fa-users' : 'fa-heart'"
                                         class="fad"></i></span>
                      <!--<input :value="job.title" class="form-control naked" placeholder="Job-Titel eingeben..." type="text"
                             @change="change($event, index, 'job.title')">-->
                      {{ job.title }}
                      <i @click="addExistingJob(job.id)" class="fal fa-plus-circle text-success float-right"></i>
                    </li>
                  </ul>

                  <button @click="showNewJob = true" class="btn btn-primary">Neuen Job erstellen</button>
                </div>
                <div class="col-md-6">
                  <h5>Hinzugefügte Jobs:</h5>
                  <ul class="fa-ul">
                    <li v-for="(job, index) in canvasJobs" :key="index">
                  <span class="fa-li"><i :class="job.type === 'functional' ? 'fa-wrench' : job.type === 'social' ? 'fa-users' : 'fa-heart'"
                                         class="fad"></i></span>
                      <!--<input :value="job.title" class="form-control naked" placeholder="Job-Titel eingeben..." type="text"
                             @change="change($event, index, 'job.title')">-->
                      {{ job.title }}
                      <i @click="removeJob(job.id)" class="fal fa-minus-circle text-danger float-right"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div v-if="step === 5">
            <h4 class="mt-3">
              <i class="fad fa-fw fa-wrench text-success"></i>
              Gains
            </h4>
            <p class="lead">
              Welche Vorteile kann das Produkt für den Kunden haben?
            </p>
            <ul class="list-group">
              <li v-for="(gain, index) in newCanvas.gains" :key="index" class="list-group-item">
                <input :value="gain" class="form-control naked" placeholder="Gain eingeben..." type="text"
                       @change="change($event, index, 'gains')">
                <i @click="remove('gains', index)" class="fal fa-minus-circle text-danger float-right"></i>
              </li>
              <li class="list-group-item">
                <input type="text" @change="add('gains', $event)" class="form-control form-control-sm" placeholder="Neuen Punkt hinzufügen..."/>
              </li>
            </ul>
          </div>
          <div v-if="step === 6">
            <h4 class="mt-3">
              <i class="fad fa-fw fa-bolt text-warning"></i>
              Pains
            </h4>
            <p class="lead">
              Welche Bedenken haben Kunden bei ihrer Aufgabe?
            </p>
            <ul class="list-group">
              <li v-for="(pain, index) in newCanvas.pains" :key="index" class="list-group-item">
                <input :value="pain" class="form-control naked" placeholder="Pain eingeben..." type="text"
                       @change="change($event, index, 'pains')">
                <i @click="remove('pains', index)" class="fal fa-minus-circle text-danger float-right"></i>
              </li>
              <li class="list-group-item">
                <input type="text" @change="add('pains', $event)" class="form-control form-control-sm" placeholder="Neuen Punkt hinzufügen..."/>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-6">
            <button @click="navigate(-1)" class="btn btn-block btn-secondary">Vorheriger Schritt</button>
          </div>
          <div class="col-6">
            <button @click="navigate(1)" class="btn btn-block btn-primary">Nächster Schritt</button>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="step === 7" class="card mb-5">
      <div class="card-header">
        <h2>
          <i class="fad fa-sparkles"></i>
          Deine Unique Value Propositions
        </h2>
      </div>
      <div class="card-body">
        <p>
          Unique Value Propositions (kurz <i>UVPs</i>) sollen dir und deinem Unternehmen helfen, den Mehrwert deiner
          Produkte oder Dienstleistungen möglichst prägnant und verständlich an den Käufer zu kommunizieren. <br>
          Ziel ist es also die Gain Creators und Pain Relievers in kurzen Sätzen zu beschreiben, die die Kunden
          auf der Webseite ansprechen sollen.
        </p>

        <div class="row">
          <div class="col-md-6 order-md-2">
            <h4>Erklärung</h4>
            <p>
              Wie kannst du bei deinen Kunden Resonanz erzeugen? <br>
              Arbeite hier am besten auch mit MVP-Ketten! MVP steht für:
            </p>
            <ul>
              <li><b>M</b>erkmale</li>
              <li><b>V</b>orteile</li>
              <li><b>P</b>ersönlicher Nutzen</li>
            </ul>

            <h5>Beispiel:</h5>
            <p>
              Das Material des Gürtels ist ein Merkmal, ist es z.B. Leder, dann wäre ein Vorteil etwa lange Haltbarkeit.
              Der Persönliche Nutzen jedoch ist noch viel näher an der Realität der Nutzer. Ein Persönlicher Nutzen wäre
              beispielsweise "Lange Zufriedenheit durch große Belastbarkeit des Leder-Materials".
            </p>
          </div>
          <div class="col-md-6">
            <h4>UVP hinzufügen:</h4>
            <ul class="">
              <li v-for="(prop, index) in newCanvas.valueProps" :key="index">
                <input :value="prop" class="form-control naked" placeholder="UVP eingeben..." type="text"
                       @change="change($event, index, 'valueProps')">
                <i @click="remove('valueProps', index)" class="fal fa-minus-circle text-danger float-right"></i>
              </li>
              <li class="mt-3">
                <input type="text" @change="add('valueProps', $event)" class="form-control form-control-sm" placeholder="Neue UVP hinzufügen..."/>
              </li>
            </ul>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-sm-6">
            <button @click="navigate(-1)" class="btn btn-block btn-secondary">Vorheriger Schritt</button>
          </div>
          <div class="col-sm-6">
            <button @click="save" class="btn btn-block btn-success">Value Proposition Canvas speichern</button>
          </div>
        </div>
      </div>
    </div>

    <button v-if="step < 7" @click="save" class="btn btn-primary">Canvas speichern</button>

  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'CanvasEdit',
  components: {},
  data() {
    return {
      isNew: true,
      step: 0,
      editSteps: [
        "Canvas erstellen",
        "Deine Produkte",
        "Behobene Probleme",
        "Entstandene Vorteile",
        "Jobs der Kunden",
        "Gesuchte Vorteile",
        "Befürchtete Nachteile",
        "Deine Wertversprechen"
      ],
      showNewJob: false,
      newCanvas: {
        id: "",
        title: "Neuer Value Proposition Canvas",
        pains: [],
        gains: [],
        gainCreators: [],
        painRelievers: [],
        products: [],
        valueProps: [],
        jobIds: [],
      },
      newJob: {
        id: "",
        title: "",
        type: "",
        description: "",
        competition: [],
        immediate: true,
        jobSteps: []
      }
    }
  },
  computed: {
    jobs() {
      return this.project.jobs.filter(job => !this.newCanvas.jobIds.find(jobId => jobId === job.id));
    },
    canvasJobs() {
      return this.newCanvas.jobIds.map(jobId => this.project.jobs.find(job => job.id === jobId));
    }
  },
  methods: {
    add(prop, $event) {
      let newVal = $event.target.value;
      if (newVal === "") return;

      this.newCanvas[prop].push(newVal);

      $event.target.value = "";
      this.update();
    },
    change($event, index, prop) {
      let newVal = $event.target.value;
      if (newVal === "") return;
      if (prop === 'job.title') {
        this.changeJob(newVal, index);
      }

      this.newCanvas[prop].splice(index, 1, newVal);

      $event.target.value = "";
      this.update();
    },
    remove(prop, index) {
      if (confirm('Remove "' + this.newCanvas[prop][index] + '" from ' + prop + '?')) {
        this.newCanvas[prop].splice(index, 1);
        this.update();
      }
    },
    addExistingJob(jobId) {
      this.newCanvas.jobIds.push(jobId);
      this.update();
    },
    addJob() {
      this.newJob.id = this.generateId();
      this.newCanvas.jobIds.push(this.newJob.id);
      this.update();
      this.project.jobs.push(this.newJob);
      this.$store.dispatch('project/updateProjectByProp', {prop: 'jobs', data: this.project.jobs})
          .then(res => {
            console.debug(res);
          });
      this.newJob = {
        id: this.generateId(),
        title: "",
        type: "",
        description: "",
        competition: [],
        immediate: true,
        jobSteps: []
      }
      this.showNewJob = false;
    },
    changeJob(newVal, index) {
      let id = this.newCanvas.jobs[index].id;
      this.project.jobs.find(job => job.id === id).title = newVal;
      this.$store.dispatch('project/updateProjectByProp', {prop: 'jobs', data: this.project.jobs})
          .then(res => {
            console.debug(res);
          });
    },
    removeJob(jobId) {
      let index = this.project.jobs.findIndex(j => j.id === jobId);
      if (confirm('Remove "' + this.project.jobs[index].title + '" from Jobs?')) {
        this.project.jobs.splice(index, 1);
        this.update();
        this.$store.dispatch('project/updateProjectByProp', {prop: 'jobs', data: this.project.jobs})
            .then(res => {
              console.debug(res);
            });
      }
    },
    navigate(direction) {
      this.step += direction;
    },
    update() {
      let index = this.project.canvas.findIndex(c => c.id === this.newCanvas.id);
      if (index >= 0) {
        this.project.canvas.splice(index, 1, this.newCanvas);
      } else {
        this.project.canvas.push(this.newCanvas);
      }
      this.$store.dispatch('project/updateProjectByProp', {prop: 'canvas', data: this.project.canvas})
          .then(res => {
            console.debug(res);
            this.$forceUpdate();
          });
    },
    save() {
      let index = this.project.canvas.findIndex(c => c.id === this.newCanvas.id);
      if (index >= 0) {
        this.project.canvas.splice(index, 1, this.newCanvas);
      } else {
        this.project.canvas.push(this.newCanvas);
      }
      this.$store.dispatch('project/updateProjectByProp', {prop: 'canvas', data: this.project.canvas})
          .then(res => {
            console.debug(res);
            this.$router.push("/canvas");
          });
    }
  },
  beforeMount() {
    if (this.$route.params.id) {
      this.newCanvas = this.project.canvas.find(c => c.id === this.$route.params.id);
    } else {
      this.newCanvas.id = this.generateId();
    }
  }
}
</script>

<style lang="scss" scoped>
li {
  position: relative;
  width: calc(100% - 1.5rem);

  &.list-item-add {
    margin-top: 1rem;
    width: 100%;
  }
  i.fa-minus-circle {
    position: absolute;
    right: -1.5rem;
    top: .3rem;
    cursor: pointer;
  }
  &::after {
    content: "";
    display: table;
    clear: bottom;
  }
}
</style>